<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";
//import router from "@/router/index.js";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Vidanges",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "vidange",
          active: true,
        },
      ],
      idVehicule: 0,
      dataUser: [],
      dataUserD: [],
      nbreUser: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,

      ordersData: [],
      fields: [
        { key: "model", sortable: true, label: "Model" },
        { key: "marque", sortable: true, label: "Marque" },
        { key: "immatri", label: "Plaque" },
        { key: "type", sortable: true, label: "Type" },
        { key: "statut", sortable: true, label: "Statut" },
        { key: "motorisation", sortable: true, label: "Moteur" },
        { key: "vidange", sortable: true, label: "Km av. vidange" },
        { key: "action", label: "Actions" },
      ],
      vidangeFields: [
        { key: "kmAvantVidange", sortable: true, label: "Km. Avant Vidange" },
        {
          key: "kmProchainVidange",
          sortable: true,
          label: "Km. Prochaine Vidange",
        },
        { key: "cout", label: "Cout" },
        { key: "facture", sortable: true, label: "Facture" },
        { key: "dateVidange", sortable: true, label: "Date Vidange" },
        { key: "action", label: "Actions" },
      ],
      vidangeData: [],
      showDisable: false,
      showDelete: false,
      showAdd: false,
      showEdit:false,
      showSucess: false,
      showEchec: false,
      showSucessEdit: false,
      showEchecEdit: false,
      userToUse: 0,
      showMore: false,
      //Model
      model: "",
      marque: "",
      datevidange: "",
      kmnextvidange: "",
      cout: 0,
      facture: "",
      //ModelEdit
      rowToEdit:0,
      kmvidangeEdit:0,
      datevidangeEdit: "",
      kmnextvidangeEdit: 0,
      coutEdit:0,
      factureEdit: 0,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      //Chargement des données
      const data = await apiRequest(
        "GET",
        "admin/entreprise/location",
        undefined,
        false
      );
      if (data && data.data && data.data.vehiculeActif) {
        const formattedTable = data.data.vehiculeActif.map((vehicule) => {
          return {
            id: vehicule.id,
            model: vehicule.model,
            marque: vehicule.marque,
            immatri: vehicule.immatriculation,
            type: vehicule.types,
            statut: vehicule.statut,
            motorisation: vehicule.motorisation,
            vidange: vehicule.kmAvantVidange,
            date: new Date(vehicule.dateCreation).toLocaleDateString("fr-FR"),
          };
        });
        //this.ordersData = formattedTable;
        formattedTable.forEach((element) => {
          if (element.statut != "LOUER") {
            this.ordersData.push(element);
          }
        });
      }
    },
    vidange(row) {
      console.log("row:", row);
      this.showAdd = true;
      this.idVehicule = row.id;
      this.plaque = row.immatri;
      this.kmvidange = row.vidange;
      this.datevidange = new Date().toLocaleDateString("fr-FR");
      this.cout = 0;
    },
    async lavidange() {
      const data = await apiRequest(
        "POST",
        "admin/entreprise/vidange",
        {
          idVehicule: this.idVehicule,
          kmAvantVidange: this.kmvidange,
          kmProchainVidange: this.kmnextvidange,
          cout: this.cout,
          facture: this.facture,
        },
        false
      );
      console.log("data.data:", data);
      if (data && data.data) {
        this.position();
        this.init();
        this.showAdd = false;
        
      }
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async showHisto(row) {
      //console.log("row:", row);
      const data = await apiRequest(
        "GET",
        "admin/entreprise/vidange/?idVehicule=" + row,
        {},
        false
      );
      if (data && data.data && data.data.vidange) {
        let vidange = data.data.vidange;
        //console.log('vidange:', vidange)
        const formattedTable = vidange.map((vidange) => {
          return {
            id: vidange.id,
            idVehicule: vidange.idVehicule,
            kmAvantVidange: vidange.kmAvantVidange,
            kmProchainVidange: vidange.kmProchainVidange,
            cout: vidange.cout,
            facture: vidange.facture,
            dateVidange: new Date(vidange.dateVidange).toLocaleDateString(
              "fr-FR"
            ),
          };
        });
        this.vidangeData = formattedTable;
        this.showMore = true;
      }
    },
    editClick(row) {
      console.log("row:", row);
      this.kmvidangeEdit = row.kmAvantVidange;
      this.kmnextvidangeEdit= row.kmProchainVidange
      this.datevidangeEdit = row.dateVidange;
      this.coutEdit = row.cout;
      this.factureEdit = row.facture;
      this.rowToEdit = row.id;

      this.showEdit = true;

    },
    async edit() {
      const data = await apiRequest(
        "PUT",
        "admin/entreprise/vidange",
        {
          id: this.rowToEdit,
          kmAvantVidange: this.kmvidangeEdit,
          kmProchainVidange: this.kmnextvidangeEdit,
          cout: this.coutEdit,
          facture: this.factureEdit,
        },
        false
      );
      console.log("data.data:", data);
      if (data && data.data) {
        this.position();
        this.showEdit = false;
        this.init();
      }
    },
    /*goToDetail(row) {
      console.log("row:", row);
      router.push(`/vehicule/details/${row.id}`);
      //router.push('/user/user_info/'+row.id)
    },*/
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Véhicules</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6"></div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="ordersData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(action)="row">
                      <button
                        class="btn btn-primary"
                        @click="vidange(row.item)"
                      >
                        Vidange
                      </button>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Historique</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    class="table-centered"
                    :items="ordersData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template v-slot:cell(action)="row">
                      <!--<a
                        href="javascript:void(0);"
                        class="text-primary"
                        v-b-tooltip.hover
                        @click="editClick()"
                        title="Editer"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>-->
                      <a
                        href="javascript:void(0);"
                        class="text-info"
                        v-b-tooltip.hover
                        @click="showHisto(row.item.id)"
                        title="Voir historique"
                      >
                        <i class="fas fa-landmark font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="showMore">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Afficher&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entrées
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Rechercher:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <b-table
                class="table-centered"
                :items="vidangeData"
                :fields="vidangeFields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:cell(action)="row">
                  <a
                    href="javascript:void(0);"
                    class="text-info"
                    v-b-tooltip.hover
                    @click="editClick(row.item)"
                    title="Voir historique"
                  >
                    <i class="mdi mdi-pencil font-size-18"></i>
                  </a>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showAdd"
      title="Vidange"
      title-class="font-18"
      hide-footer
    >
      <form
        class="form-horizontal"
        role="form"
        method="post"
        @submit.prevent="showAdd"
      >
        <div class="row">
          <div class="col-md-6">
            <label for="">Immatriculation</label>
            <input type="text" class="form-control" v-model="plaque" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Kilométrage avant vidange</label>
            <input type="text" class="form-control" v-model="kmvidange" id="" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">Date de vidange</label>
            <input
              type="text"
              class="form-control"
              v-model="datevidange"
              id=""
            />
          </div>
          <div class="col-md-6">
            <label for="">Kilométrage prochaine vidange</label>
            <input
              type="text"
              class="form-control"
              v-model="kmnextvidange"
              id=""
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">Coût</label>
            <input type="text" class="form-control" v-model="cout" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Référence facture</label>
            <input type="text" class="form-control" v-model="facture" id="" />
          </div>
        </div>
        <button class="btn btn-success float-right mt-4" @click="lavidange">
          Valider
        </button>
      </form>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEdit"
      title="Modifié Vidange"
      title-class="font-18"
      hide-footer
    >
      <form
        class="form-horizontal"
        role="form"
        method="post"
        @submit.prevent="showEdit"
      >
        <div class="row">
          <div class="col-md-6">
            <label for="">Kilométrage avant vidange</label>
            <input type="text" class="form-control" v-model="kmvidangeEdit" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Kilométrage prochaine vidange</label>
            <input
              type="text"
              class="form-control"
              v-model="kmnextvidangeEdit"
              id=""
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">Coût</label>
            <input type="text" class="form-control" v-model="coutEdit" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Référence facture</label>
            <input type="text" class="form-control" v-model="factureEdit" id="" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">Date de vidange</label>
            <input
              type="text"
              class="form-control"
              v-model="datevidangeEdit"
              id=""
            />
          </div>
        </div>
        <button class="btn btn-success float-right mt-4" @click="edit">
          Valider
        </button>
      </form>
    </b-modal>
  </Layout>
</template>
